<template>
    <div>
        <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            outlined dense
        >
        </v-text-field>
        <v-data-table 
            v-if="userData"
            :search="search"
            :headers="headers"
            :items="userData"
            :items-per-page="5"
            no-data-text="データーがありません"
            item-key="_id"
            v-model="selectedUser"
            :footer-props="{
                showFirstLastPage: true,
                'items-per-page-text': '表示数',
                'items-per-page-options': [15, 50, -1],
            }"
            show-select
        >
        </v-data-table>
        <div class="d-flex" v-if="syncOnConfirmation">
            <v-spacer></v-spacer>
            <v-btn color="success" @click="$emit('syncData',selectedUser)">確定</v-btn>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        usersOnly:Boolean,
        show:Object,
        listOfUsersToFilter:Array,
        syncOnConfirmation:Boolean,
    },
    data:()=>({
        search:"",
        userData: null,
        selectedUser : [],
        headers:[
        ],
    }),
    async mounted(){
        this.userData = await this.getAllUser()
        this.headers = this.generateHeaders()
        if(this.listOfUsersToFilter && this.listOfUsersToFilter.length > 0)
            this.userData = this.filterUsers(this.userData, this.listOfUsersToFilter)
    },
    watch:{
        selectedUser(){
            if(!this.syncOnConfirmation){
                this.$emit('syncData',this.selectedUser)
            }
        }
    },
    methods:{
        generateHeaders(){
            let defaultHeader = [
                {
                    text: "名前",
                    align: "start",
                    value: "name",
                },
                { text: "所属", value: "userTeam.teamName"},
            ]
            if(!this.show) return defaultHeader
            if(this.show.userPin) defaultHeader.push({text:"社員コード", value:"userPin"})
            if(this.show.userContractType) defaultHeader.push({text:"雇用区分", value:"userContractType"})
            if(this.show.joined) defaultHeader.push({text:"入社日", value:"joined"})
            return defaultHeader
        },
        filterUsers(users, filterList){
            users = users.filter(user => {
                if(!filterList.includes(user._id))
                    return user
            })
            return users
        },
        async getAllUser(){
            const response = await this.$store.dispatch('get', {url:`/api/getUserList?uOnly=${this.usersOnly?true:false}`, showUI:true})
            if(response.result)
                return response.result
        }
    }
}
</script>