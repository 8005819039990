<template>
    <div>
        <div class="banner pa-5 my-3 text-center text-h3 rounded">単価設定</div>
        <div class="error--text">適応する集計方法は、「その他の設定」の中に指定してください。</div>
        <div class="d-flex justify-space-around ma-5">
            <v-btn @click="selectedComponents='IndividualRate'" class="text-h4 pa-6 primary" width="25%">個人別</v-btn>
            <v-btn @click="selectedComponents='GroupRate'" class="text-h4 pa-6 primary" width="25%">グループ別</v-btn>
        </div>
        <component :is="selectedComponents"></component>
    </div>
</template>

<script>
import IndividualRate from '@/components/RateSettings/IndividualRate'
import GroupRate from '@/components/RateSettings/GroupRate'

export default {
    data:()=>({
        selectedComponents:null
    }),
    components:{
        IndividualRate,
        GroupRate
    }
}
</script>


<style scoped>

</style>