<template>
    <div v-if="individualRateData">
        <div class="d-flex my-3">
            <v-spacer></v-spacer>
            <v-btn color=success @click="updateIndividualRate">保存</v-btn>
        </div>
        <v-text-field
            outlined
            dense
            prepend-inner-icon="mdi-magnify"
            v-model="search"
        >
        </v-text-field>
        <v-data-table
            height="90vh"
            :search="search"
            :headers="headers"
            :items="individualRateData"
            no-data-text="データーがありません"
            :footer-props="{
                showFirstLastPage: true,
                'items-per-page-text': '表示数',
                'items-per-page-options': [10, 50, -1],
            }"
        >
            <template v-slot:item.userRate="{item}">
                <div class="d-flex">
                    {{mutateEmptyRate(item)}}
                    <v-select
                        style="max-width:50%;"
                        v-model="item.userRate.rateType"
                        :hide-details="true"
                        outlined dense
                        :items="[
                            { text: '時給', value: 1 },
                            { text: '日給', value: 2 },
                            { text: '月給', value: 3 },
                        ]"
                        @change="applyChanges(item)"
                    >
                    </v-select>
                    <v-text-field
                        style="max-width:50%;"
                        type="number"
                        outlined dense
                        :hide-details="true"
                        v-model="item.userRate.rateValue"
                        @change="applyChanges(item)"
                    >
                    </v-text-field>
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
export default {
    data: () => ({
        search: "",
        headers: [
            {text:"名前", value:"name"},
            {text:"所属", value:"userTeam.teamName"},
            {text:"単価(¥)", value:"userRate"},
        ],
        individualRateData: null,
        updatedUsers:{}
    }),
    async mounted() {
        this.individualRateData = await this.$get.getUserRate(1)
    },
    methods: {
        mutateEmptyRate(target){
            if(!target.userRate)
                target.userRate = {}
        },
        async updateIndividualRate(){
            await this.$store.dispatch("post", {
                url: "/api/updateIndividualRate",
                showUI:true,
                rawData:this.updatedUsers
            });
        },
        applyChanges(user){
            this.updatedUsers[user._id] = {
                rateType:user.userRate.rateType,
                rateValue:user.userRate.rateValue
            }
        },
    },
};
</script>

<style>
</style>