<template>
    <div v-if="groupData" :key="'componentKey'+componentKey">
        <div class="d-flex my-3">
            <div v-if="timer" class="error--text">まだ保存されていません。{{count/1000}}秒後自動的に保存します。</div>
            <v-spacer></v-spacer>
            <v-btn color="success" @click="initNewForm">
                <v-icon>
                    mdi-plus
                </v-icon>
                新規グループ
            </v-btn>
        </div>
        <v-text-field v-model="search" outlined dense prepend-inner-icon="mdi-magnify"/>
        <v-data-table
            :search=search
            height="80vh"
            :headers="headers"
            :items="groupData"
            :items-per-page="5"
            no-data-text="データーがありません"
            item-key="_id"
            :footer-props="{
                showFirstLastPage: true,
                'items-per-page-text': '表示数',
                'items-per-page-options': [10, 50, -1],
            }"
            show-select
            v-model="selectedGroups"
        >
            <template v-slot:item.rateSettings="{item}">
                {{$gf.convertRateType(item.rateType)}} {{$gf.convertYen(item.rateValue)}}
            </template>
            <template v-slot:item.userCount="{item}">
                {{item.userList.length}}
            </template>
            <template v-slot:item.actions="{item}">
                <v-btn icon color=error @click="initDelete(item._id)">
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
                <v-btn icon color=success @click="initGroupWindow(item)">
                    <v-icon>mdi-account-switch-outline</v-icon>
                </v-btn>
                <v-btn icon color=primary @click="initEditMode(item)">
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
            </template>
        </v-data-table>
        <!--Dialogs -->
        <v-dialog persistent v-if="display.initCreateNewGroupForm" v-model="display.initCreateNewGroupForm" max-width="500px">
            <v-sheet class="pa-3">
                <v-form v-model="formData.valid" >
                    <v-text-field
                        outlined
                        v-model="formData.name"
                        label="グループ名"
                        :rules="[v=> !!v && (v.length >= 2 && v.length<=20) || '4文字以上20文字以下を入力してください']"
                    >    
                    </v-text-field>

                    <v-select
                        label="単価区分"
                        v-model="formData.rateType"
                        outlined
                        :items="[
                            { text: '時給', value: 1 },
                            { text: '日給', value: 2 },
                            { text: '月給', value: 3 },
                        ]"
                        :rules="[(v) => !!v || '必須項目です']"
                    >
                    </v-select>

                    <v-text-field
                        outlined
                        type=number
                        v-model.number="formData.rateValue"
                        label="単価"
                        :rules="[v => !isNaN(v) && !!v || '必須項目です']"
                    >    
                    </v-text-field>
                    <div v-if="!editMode">
                        <v-btn class="primary mr-3" @click="initUserList()">ユーザーを追加</v-btn>
                        <span>{{formData.userList.length}}人を選択されています</span>
                    </div>
                    <div class="d-flex">
                        <v-spacer></v-spacer>
                        <v-btn v-if="editMode" color=success @click="initUpdateGroupInfo">確定</v-btn>
                        <v-btn v-else color=success @click="createNewRateGroup">確定</v-btn>
                        <v-btn v-if="!editMode" color="error" text @click="display.initCreateNewGroupForm=false">キャンセル</v-btn>
                    </div>
                </v-form>
            </v-sheet>
        </v-dialog>
        <!--UserDialog-->
        <v-dialog persistent max-width="500px" v-if="display.userList" v-model="display.userList">
            <v-sheet class="pa-3">
                <v-card flat>
                    <v-card-text>
                        <user-list 
                            :listOfUsersToFilter=groupUserIds 
                            @syncData="addUserToGroup"
                            :syncOnConfirmation="interceptFormDataUserList ? true: false"
                        />
                    </v-card-text>
                    <v-card-actions v-if="!interceptFormDataUserList">
                        <v-spacer></v-spacer>
                        <v-btn color="error" @click="display.userList = false">閉じる</v-btn>
                    </v-card-actions>
                </v-card>
            </v-sheet>
        </v-dialog>
        <group-window 
            v-for="window in groupWindow" :key="window._id" 
            :inputGroupData="window"
            @close="closeWindow"
            @updated="activateSyncSequence"
            @addNewUser="initAddUserToGroup"
        />
    </div>
</template>

<script>
//Need to add filter then adding new group to prevent same user in multiple groups
import UserList from "../DataTables/UserList";
import GroupWindow from './GroupRate/GroupWindow'
export default {
    data: () => ({
        collectWindows:false,
        componentKey:0,
        search:"",
        selectedGroups:[],
        backup:{
            
        },
        formData:{
            valid:false,
            rateValue:0,
            rateType:1,
            userList:[]
        },
        display:{
            initCreateNewGroupForm:false,
            userList:false
        },
        headers: [
            {
                text: "グループ名",
                align: "start",
                value: "name",
            },
            { text: "単価設定", value: "rateSettings" },
            { text: "人数", value: "userCount" },
            { text: "操作", value: "actions" },
        ],
        groupData: null,
        groupWindow:[
            /*
                EX
                "groupId1":{ groupData },
                "groupId2":{ groupData}
            */
        ],
        groupUserIds:[],
        //Syncs
        timer:null,
        count:5000,
        editMode:false,
        interceptFormDataUserList:false,
        targetGroupId:null,
        //tmpSyncDataHistory
        updatedGroups:{
            /*groupIds:<data>*/
        }
    }),
    async mounted() {
        this.groupData = await this.$get.getUserRate(2)
    },
    methods: {
        async initUpdateGroupInfo(){
            let tmpData = {}
            tmpData[this.formData._id] = this.formData
            await this.updateRateGroups(tmpData)
            this.display.initCreateNewGroupForm = false
            this.editMode = false
        },
        initEditMode(targetGroup){
            this.formData = targetGroup
            this.backup = targetGroup
            for(let i = 0; i<this.groupData.length ; i++)
                if(this.groupData._id === this.formData._id)
                    this.groupData = this.formData
            this.display.initCreateNewGroupForm = true
            this.editMode = true
        },
        initAddUserToGroup(e){
            this.initUserList()
            this.targetGroupId=e;
            this.interceptFormDataUserList = true;
            this.display.userList=true;
        },
        addUserToGroup(e){
            if(this.interceptFormDataUserList){
                if(this.targetGroupId){
                    for(let i = 0 ; i<this.groupData.length ; i++)
                        if(this.groupData[i]._id === this.targetGroupId){
                            this.groupData[i].userList = this.groupData[i].userList.concat(e)
                        }       
                }
                this.display.userList=false
            }else{
                this.formData.userList = e
            }  
        },
        activateSyncSequence(e){
            this.count = 5000
            clearInterval(this.timer)
            this.updatedGroups[e.groupId] = e.groupData
            this.timer = setInterval(()=>{
                this.count -= 1000
                if(this.count === 0){
                    clearInterval(this.timer)
                    this.timer = null
                    this.updateRateGroups(this.updatedGroups)
                }
            },1000)
        },
        async updateRateGroups(updatedData){
            await this.$store.dispatch("post", {
                url: "/api/updateRateGroups",
                showUI: true,
                rawData: updatedData,
            });
        },
        async initDelete(selectedId){
            let selected
            if(this.selectedGroups.length > 0) selected = this.selectedGroups
            else selected = [selectedId]
            if (
                await this.$root.$confirm.open(
                    "削除",
                    "選択した項目を全部削除しますか？元には戻れません。",
                    { color: "error" }
                )
            ){
                await this.$store.dispatch("post", {
                    url: "/api/deleteRateGroups",
                    showUI: true,
                    rawData: selected,
                });
                this.groupData = await this.$get.getUserRate(2)
            }
        },
        initNewForm(){
            this.formData ={
                valid:false,
                rateValue:0,
                rateType:1,
                userList:[]
            }
            this.display.initCreateNewGroupForm = true
        },
        initUserList(){
            this.interceptFormDataUserList = false
            this.groupUserIds = []
            this.groupData.forEach(group => {
                group.userList.forEach(user => {
                    this.groupUserIds.push(user._id)
                })
            });
            this.display.userList = true
        },
        async createNewRateGroup(){
            await this.$store.dispatch('post', {url:'/api/createNewRateGroup', showUI:true, rawData:this.formData})
            this.display.initCreateNewGroupForm = false
            this.groupData = await this.$get.getUserRate(2)
        },
        initGroupWindow(item){
            //Check if it's open
            for(let i = 0 ; i<this.groupWindow.length ; i++)
                if(this.groupWindow[i]._id === item._id)
                    return
            //
            item.position = {}
            item.position.x = 0
            item.position.y = 0
            this.groupWindow.push(item)
        },
        closeWindow(id){
            for(let i = 0 ; i<this.groupWindow.length ; i++){
                if(this.groupWindow[i]._id === id){
                    this.groupWindow.splice(i,1)
                    break
                }
            }
            
        }
    },
    components: {
        UserList,
        GroupWindow
    },
};
</script>

<style>
</style>