<template>
    <v-sheet
        v-if="groupData"
        class="window rounded"
        :id="'window' + groupData._id"
    >
        <div class="handle windowHandle" @click="$emit('tofront')">
            <span :style="'color:' + currentTheme.windowBackground">
                {{ groupData.name }}
            </span>
            <span class="control">
                <v-btn light tile x-small text @click="$emit('close', groupData._id)">
                    <v-icon :color="currentTheme.windowBackground"
                        >mdi-close-circle-outline</v-icon
                    >
                </v-btn>
            </span>
        </div>
        <div
            :style="
                'max-height:500px; overflow-y: scroll; border:1px solid' +
                currentTheme.windowHandle
            "
        >
            <div class="caption warning--text text-center pt-3">
                <v-icon x-small>mdi-arrow-up-thick</v-icon>
                このパネルは移動できます
                <v-icon x-small>mdi-arrow-up-thick</v-icon>
            </div>
            <div class="caption error--text text-center pt-3">
                <v-icon x-small>mdi-arrow-down-thick</v-icon>
                ユーザーをドラッグして、他のグループに移動できます
                <v-icon x-small>mdi-arrow-down-thick</v-icon>
            </div>
            <div class="pa-3">
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th colspan="3" class="text-center">
                                    <v-chip>
                                    {{
                                        $gf.convertRateType(groupData.rateType)
                                    }}
                                    {{
                                        new Intl.NumberFormat("ja-JP", {
                                            style: "currency",
                                            currency: "JPY",
                                        }).format(groupData.rateValue)
                                    }}
                                    </v-chip>
                                </th>
                            </tr>
                            <tr>
                                <th class="text-left">名前</th>
                                <th class="text-left">所属</th>
                                <th class="text-left">操作</th>
                            </tr>
                            <tr>
                                <td colspan="3" class="text-center">
                                    <v-btn
                                        color="success"
                                        @click="$emit('addNewUser', groupData._id)"
                                    >
                                        <v-icon>mdi-plus</v-icon>
                                        ユーザー追加
                                    </v-btn>
                                </td>
                            </tr>
                        </thead>
                       
                        <draggable
                            group="people"
                            style="cursor: pointer"
                            tag="tbody"
                            :list="groupData.userList"
                        >
                            <tr
                                v-for="user in groupData.userList"
                                :key="user._id"
                            >
                                <td>
                                    {{ user.name }}
                                </td>
                                <td>
                                    {{ user.userTeam.teamName }}
                                </td>
                                <td>
                                    <v-btn
                                        icon
                                        color="error"
                                        @click="removeUserFromGroup(user._id)"
                                    >
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </draggable>
                    </template>
                </v-simple-table>
            </div>
        </div>
    </v-sheet>
</template>

<script>
import draggable from "vuedraggable";
import interact from "interactjs";
import UserList from "../../DataTables/UserList";
export default {
    components: {
        draggable,
        UserList,
    },
    props: ["inputGroupData"],
    data: () => ({
        currentTheme: null,
        init: false,
        position: { x: 0, y: 0 },
        groupData: null,
        inited:false,
        groupUserList:null
    }),
    watch: {
        groupData:{
            handler(){
                if(this.groupData.userList.length !== this.groupUserList.length)
                    this.groupUserList = this.groupData.userList
            },
            deep:true
        },
        groupUserList(){
            if(this.inited)
                this.$emit("updated", {
                    groupId: this.groupData._id,
                    groupData: this.groupData,
                });
            else this.inited = true
        },
    },
    mounted() {
        if (this.$vuetify.theme.isDark)
            this.currentTheme = this.$vuetify.theme.defaults.dark;
        else this.currentTheme = this.$vuetify.theme.defaults.light;
        this.groupData = this.inputGroupData;
        this.groupUserList = this.groupData.userList
        this.position = this.groupData.position;
        this.initDraggable();
    },
    methods: {
        removeUserFromGroup(id) {
            this.groupData.userList = this.groupData.userList.filter((user) => {
                if (user._id !== id) return user;
            });
            this.groupUserList = this.groupData.userList
        },
        initDraggable() {
            let init = this.init;
            let position = this.position;
            let store = this.$store;
            interact("#window" + this.groupData._id).draggable({
                allowFrom: ".handle",
                listeners: {
                    start(event) {
                        if (!init) {
                            let tData = event.target.getBoundingClientRect();
                            position.x -= tData.width / 2;
                            position.y -= tData.height / 2;
                            init = true;
                        }
                    },
                    move(event) {
                        let tData = event.target.getBoundingClientRect();
                        //When hit left
                        if (tData.x <= 0)
                            position.x = -(store.state.screen.w / 2);
                        //When right
                        if (tData.x + tData.width > store.state.screen.w)
                            position.x = store.state.screen.w / 2 - tData.width;
                        //When hit top
                        if (tData.y <= 0)
                            position.y =
                                -(store.state.screen.h / 2) -
                                132 +
                                window.scrollY;
                        //When hit bottom
                        if (tData.y + tData.height > store.state.screen.h)
                            position.y = -(
                                document.body.clientHeight -
                                store.state.screen.h -
                                (store.state.screen.h / 2 -
                                    tData.height +
                                    132) -
                                window.scrollY
                            );
                        position.x += event.dx;
                        position.y += event.dy;
                        event.target.style.transform = `translate(${position.x}px, ${position.y}px)`;
                    },
                },
            });
        },
    },
};
</script>

<style scoped>
.window {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 350px;
}
.handle {
    position: relative;
    color: black;
    font-family: "Montserrat";
    font-weight: bold;
    width: 100%;
    background: white;
    border-radius: 5px 5px 0 0;
    text-align: center;
    padding: 2px;
}
.control {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}
</style>